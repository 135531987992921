import axios from "axios";
import superagent from "superagent";
import conf from "./apiconfig.json";
import errorMsg from "./errorMessages.json";
import Auth from "./auth";
import User from "./User";

let env = process.env.REACT_APP_ENV || "dev";

let token = {
  expires_at: 0,
  token: 0,
};
let datasetId;

const Dataset = {
  upload: async (fileName, file, jwt) => {
    let response = null;
    try {
      response = await superagent
        .post(`${conf.envs[env]["strapi-url"]}/datasets/uploadToS3`)
        .set("Authorization", `Bearer ${jwt}`)
        .attach("files", file)
        .field("data", JSON.stringify({ fileName }));
    } catch (err) {
      return err;
    }
    return response;
  },
  post: async (body, jwt) => {
    return axios
      .post(
        conf["strapi-url"] + "/datasets",
        { dataobject: body },
        {
          headers: {
            Authorization: "Bearer " + jwt,
          },
        }
      )
      .then((response) => {
        console.log("Successfully posted dataset");
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  verifyChunk: async (dataset, jwt) => {
    console.log(Auth.getBasicAuth());
    return axios
      .post(
        conf.envs[env]["amygdala-url"] + "/api/checkError/",
        JSON.stringify({ data: dataset }),
        {
          headers: {
            Authorization: `Basic ${Auth.getBasicAuth()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  inferChunk: async (dataset, jwt) => {
    return axios
      .post(
        conf.envs[env]["amygdala-url"] + "/api/infer/",
        JSON.stringify({ data: dataset }),
        {
          headers: {
            Authorization: `Basic ${Auth.getBasicAuth()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
        return error;
      });
  },
  notify: async (dataset, jwt) => {
    return axios
      .post(
        conf.envs[env]["amygdala-url"] + "/api/notify/",
        JSON.stringify({ data: dataset }),
        {
          headers: {
            Authorization: `Basic ${Auth.getBasicAuth()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response.data);
        return error;
      });
  },
  startRecording: async (media, user) => {
    // Check if we have valid token, if not regenerate it
    if (token.expires_at < Date.now() - conf.configVars.token_delay) {
      token = await User.getNewAuthToken(user.jwt);
    }
    let dataobject = {
      deviceId: "2",
      userId: user.id,
      mediaId: media.id,
    };

    axios
      .post(
        conf.envs[env]["amygdala-url"] +
          conf.amyEndpoints.startRecordingEndpoint,
        JSON.stringify(dataobject),
        {
          headers: {
            Authorization: "Bearer " + token.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        datasetId = response.data.datasetId;
      })
      .catch((error) => {
        if (env === "dev") console.log(error.response);
        alert(errorMsg.serverConnectError);
        return window.location.reload();
      });
  },
  postRecordingChunk: async (events, headset, jwt) => {
    // Check if we have valid token, if not regenerate it
    if (token.expires_at < Date.now() - conf.configVars.token_delay) {
      token = await User.getNewAuthToken(jwt);
    }
    let dataobject = {
      datasetId: datasetId,
      events: events,
      headset: headset,
    };
    axios
      .post(
        conf.envs[env]["amygdala-url"] +
          conf.amyEndpoints.postRecordingEndpoint,
        JSON.stringify(dataobject),
        {
          headers: {
            Authorization: "Bearer " + token.token,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (env === "dev") {
          console.log(error.response);
        }
        alert(errorMsg.serverConnectError);
        return window.location.reload();
      });
  },
  endRecording: async (emotions, jwt) => {
    // Check if we have valid token, if not regenerate it
    if (token.expires_at < Date.now() - conf.configVars.token_delay) {
      token = await User.getNewAuthToken(jwt);
    }
    let dataobject = {
      datasetId: datasetId,
      perceivedEmotion: emotions.perceivedEmotion,
      happy: emotions.happy,
      control: emotions.control,
      excited: emotions.excited,
    };
    axios
      .post(
        conf.envs[env]["amygdala-url"] + conf.amyEndpoints.endRecordingEndpoint,
        JSON.stringify(dataobject),
        {
          headers: {
            Authorization: "Bearer " + token.token,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (env === "dev") {
          console.log(error.response);
        }
        alert(errorMsg.serverConnectError);
        return window.location.reload();
      });
  },
};

export default Dataset;
