import React, { useState, useEffect } from 'react'
import RadarChart from 'react-svg-radar-chart';

import Loading from './Loading'

const InferenceModal = ({ inferredData, setInferenceModal }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [captions, setCaptions] = useState(null)

    useEffect(() => {
        if (inferredData) {
            setLoading(false)
            if (inferredData.predictions && inferredData.captions) {
                setData(inferredData.predictions)
                setCaptions(inferredData.captions)
            } else {
                alert("Error when requesting inference data.")
                setInferenceModal("closed")
            }
        } 
    }, [inferredData])

    return <div className="fullscreen" style={{ zIndex: 200 }}>
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(150, 150, 150, 0.9)'
        }}>
            <Loading 
                title={"Inference Data"} 
                modal={true} 
                loading={loading}
            >
                {data && captions && <><RadarChart
                    data={data}
                    captions={captions}
                    size={450}
                />
                <button onClick={() => setInferenceModal("closed")}>Close</button></>}
            </Loading>
        </div>
    </div>
}

export default InferenceModal