import React from 'react'

const Loading = ({ title, modal=false, loading=true, children }) => {
    return(
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div style={{
                maxWidth: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                padding: "1rem",
                backgroundColor: modal ? "white" : null,
            }}>
                {title && <h4>{title}</h4>}
                {loading && <img
                    src={require('../assets/Rolling-1s-200px.svg')}
                    alt="Loading..."
                    height="100px"
                />}
                {children}
            </div>
        </div>
    )
}

export default Loading