import React, { useState, useEffect, useCallback } from "react";
import "../styles/App.css";

import { useGlobalState } from "../components/State";

import VideoPlayer from "../components/VideoPlayer";
import Timer from "../components/Timer";
import PegGame from "../components/PegGame";
import OneBackGame from "../components/OneBackGame";
import MoodMatrix from "../components/MoodMatrix";
import InferenceModal from "../components/InferenceModal";
import {
  INFER_DATA,
  BEGIN_SESSION_RECORDING,
  END_SESSION_RECORDING,
  STOP_DATA_RECORDING,
} from "../components/DataMachine";

const SessionView = ({
  item,
  media,
  connected,
  createEvent,
  setRepeatSession,
  inferredData,
  cancelSession,
}) => {
  const [user, setUser] = useGlobalState("user");
  const [currentMedia, setCurrentMedia] = useState();
  const [title, setTitle] = useState();
  const [videoPlayer, setVideoPlayer] = useState();
  const [started, setStarted] = useState(false);
  const [complete, setComplete] = useState(false);
  // this width is a hacky fix for safari being weird, hopefully it can be deleted in future
  const [width, setWidth] = useState("99%");
  const [moodMatrix, setMoodMatrix] = useState(false);
  const [inferenceModal, setInferenceModal] = useState(false);
  const [perceivedEmotion, setPerceivedEmotion] = useState();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    // hacky thing for safari
    setWidth("100%");

    // title calculation to show if game or just show number if numbered session
    if (item.Title[0].match(/^-{0,1}\d+$/)) {
      setTitle(item.Title[0]);
    } else {
      setTitle(item.Title);
    }

    // hacky fix to have grey blackout box on games too
    if (media !== "video") setCurrentMedia("game");

    if (!user.completedSessions) return;
    let matchingCompletedSessions = user.completedSessions.filter(
      (compSession) => compSession.id === item.id
    );
    setRepeatSession(matchingCompletedSessions.length);
  }, [item.Title, item.id, media, setRepeatSession, user.completedSessions]);

  const inferenceTimeComplete = async () => {
    setComplete(true);
    await moodMatrixFcn();
    INFER_DATA();
    setInferenceModal(true);
  };

  useEffect(() => {
    if (inferenceModal === "closed") {
      endSession();
    }
  }, [inferenceModal]);

  useEffect(() => {
    if (cancelSession) {
      console.debug("Session cancelled due to error");
      videoPlayer.pause();
    }
  }, [cancelSession]);

  useEffect(() => {
    if (videoPlayer && currentMedia && moodMatrix) {
      videoPlayer.pause();
    } else if (
      videoPlayer &&
      currentMedia &&
      videoPlayer.children[0].getAttribute("src") === currentMedia.videoLink
    ) {
      videoPlayer.play();
    }
  }, [videoPlayer, currentMedia, moodMatrix]);

  const videoEvent = async (event) => {
    // eslint-disable-next-line default-case
    switch (event.type) {
      case "canplaythrough":
        if (videoPlayer && currentMedia && !moodMatrix) videoPlayer.play();
        break;
      case "play":
        if (videoPlayer.currentTime === 0) {
          createEvent(`started playing ${currentMedia.title}`);
        } else createEvent(`resumed ${currentMedia.title}`);
        break;
      case "pause":
        if (videoPlayer.currentTime < videoPlayer.duration)
          createEvent(`paused ${currentMedia.title}`);
        break;
      case "ended":
        STOP_DATA_RECORDING();
        createEvent(`finished playing ${currentMedia.title}`);

        setCurrentMedia(null);

        setComplete(true);

        await moodMatrixFcn();
        await END_SESSION_RECORDING(perceivedEmotion);
        if (item.inference) {
          INFER_DATA();
          return setInferenceModal(true);
        }
        window.location.reload();

        break;
      case "fullscreen":
        createEvent(`fullscreen toggled ${event.value}`);
        break;
    }
  };

  const gameEvent = (event) => {
    createEvent(event);
  };

  const beginSession = async () => {
    if (!connected) return alert("Connect device before starting media.");
    await moodMatrixFcn();
    BEGIN_SESSION_RECORDING(item.media[0]);
    setStarted(true);
    createEvent("started session");
    if (item.inference && (!item.media || !item.media[0]))
      return setCurrentMedia("blank-inference");
    if (media === "video") {
      let part1 = item.media.filter((media) => media.title.includes(".1"))[0];
      if (part1) {
        setCurrentMedia(part1);
      } else setCurrentMedia(item.media[0]);
    }
  };

  const endSession = async () => {
    createEvent("completed session");
  };

  const moodMatrixFcn = () => {
    return new Promise((resolve) => {
      setMoodMatrix(
        <MoodMatrix
          onSubmit={(
            happy,
            excited,
            control,
            perceivedEmotion,
            currentState
          ) => {
            createEvent("mood given (happy, excited, control)", {
              happy,
              excited,
              control,
            });
            createEvent("perceived emotion", perceivedEmotion);
            createEvent("current state", currentState);
            setPerceivedEmotion({
              happy: happy,
              excited: excited,
              control: control,
              perceivedEmotion: perceivedEmotion,
            });
            resolve();
            setMoodMatrix(false);
          }}
        />
      );
    });
  };

  const mediaSwitch = () => {
    if (!currentMedia || !started)
      return (
        <div
          style={{
            width: "720px",
            height: "405px",
            backgroundColor: "#707070",
            margin: "0 auto",
          }}
        />
      );
    switch (media) {
      case "video":
        return item.media && item.media[0] ? (
          <VideoPlayer
            src={currentMedia.videoLink}
            setVideoPlayer={setVideoPlayer}
            videoEvent={videoEvent}
          />
        ) : item.inference ? (
          <div
            style={{
              width: "720px",
              height: "405px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "450px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                This is a free inference session. You may do what you like for
                10 minutes. We will show you what we believe you are feeling at
                the end.
              </p>
              <p>
                Please keep this window open and visible in case any headset
                error alerts appear.
              </p>
              <Timer
                text={""}
                time={Date.now() + 60000}
                setTimerComplete={inferenceTimeComplete}
                alignStart={false}
              />
            </div>
          </div>
        ) : (
          <p>No media has been assigned to this session, oops!</p>
        );
        break;
      case "peg-game":
        return <PegGame gameEvent={gameEvent} />;
        break;
      case "1-back-game":
        return <OneBackGame gameEvent={gameEvent} />;
        break;
      case "blank-inference":
        break;
      default:
        return (
          <div
            style={{
              width: "720px",
              height: "405px",
              backgroundColor: "#707070",
              margin: "0 auto",
            }}
          ></div>
        );
        break;
    }
  };

  return (
    <div style={{ width }}>
      {moodMatrix && moodMatrix}
      {inferenceModal && inferenceModal !== "closed" && (
        <InferenceModal
          inferredData={inferredData}
          setInferenceModal={setInferenceModal}
        />
      )}
      <div className="container align-center">
        <div className="full-width align-center">
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png")}
            alt="Kouo"
          />
        </div>
        <div className="media-info">
          <h2 style={{ marginTop: 0 }}>{title}</h2>
          {media === "video" && (
            <p>
              Tip: Fullscreen browser window then click fullscreen on player to
              minimise distractions. Videos will automatically leave fullscreen
              upon completion.
            </p>
          )}
          {item.inference && (
            <p>
              This is an inference session. We will collect data from your
              headset over the duration. Once the session has finished we will
              run your data through our model and display the results to you!
            </p>
          )}
          {media === "peg-game" && (
            <>
              <p>
                Welcome to the Peg Turning Game. The objective is to rotate the
                pegs so that the slots are aligned into two straight lines.
              </p>
              <p>
                You will be able to submit data after 5 minutes but you may keep
                playing for as long as you wish.
              </p>
            </>
          )}
          {media === "1-back-game" && (
            <>
              <p>
                Welcome to the 1 Back Game. The objective is to click when the
                number that appears is the same as the number before the
                previous number (not the previous number).
              </p>
              <p>
                You will be able to submit data after playing for 5 minutes but
                you may keep playing for as long as you wish.
              </p>
            </>
          )}
        </div>
        <div style={{ height: "405px", marginBottom: "8rem" }}>
          {mediaSwitch()}
        </div>
      </div>
      {!started && (
        <button
          className="button"
          onClick={beginSession}
          style={{
            position: "absolute",
            top: media === "video" ? "28rem" : "31rem",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Start Session
        </button>
      )}
    </div>
  );
};

export default SessionView;
