import React, {useState, useEffect} from 'react'

import '../styles/App.css'

const VideoPlayer = ({ src, setVideoPlayer, videoEvent }) => {
    const [fullScreen, setFullScreen] = useState(false)
    useEffect(() => { setVideoPlayer(document.querySelector("#vid")) }, [])

    return (
        <div className="align-items-center">
            <div className={`video-player ${fullScreen ? "fullscreen" : null}`}>
            <video 
                id="vid"
                preload="metadata" 
                onCanPlayThrough={videoEvent}
                onPlay={videoEvent}
                onPause={videoEvent}
                onEnded={(e) => {
                    setFullScreen(false)
                    videoEvent(e)
                }}
                className={`video-player ${fullScreen ? "fullscreen" : null}`}
                // below are needed for iOS safari
                // playsInline={true}
                // muted={true}
            >
                <source src={src}
                        type="video/mp4" />
            </video>
            {fullScreen && <div style={{position: "absolute", top: "0", left: "0", width: "100vw", height: "100vh", backgroundColor: "black", zIndex: "9"}}>&nbsp;</div>}
            <button 
                style={{position: "absolute", bottom: "0", right: "0", zIndex: "999"}}
                onClick={() => {
                    setFullScreen(!fullScreen)
                    videoEvent({type: 'fullscreen', value: !fullScreen})
                }}
            >
                fullscreen
            </button>
            </div>
        </div>
    )
}

export default VideoPlayer
