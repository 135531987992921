import React, {useState, useEffect} from 'react'
import { useGlobalState } from '../components/State'

import User from '../api/User'

import '../styles/App.css'
import '../styles/title.css'
import '../styles/login.css'

const Login = () => {
    const [user, setUser] = useGlobalState('user')

    // Login variables
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    // Signup variables
    const [emailSignup, setEmailSignup] = useState('')
    const [passwordSignup, setPasswordSignup] = useState('')
    const [gender, setGender] = useState('Male')
    const [age, setAge] = useState(18)
    const [language, setLanguage] = useState('')
    const [deviceId, setDeviceId] = useState('')
    const [signupCode, setSignupCode] = useState('')

    return(
        <div className="container justify-center align-center">
            <div className="animated-title align-center">
                <img className="title-text" src={require("../assets/kouo.png")} alt="Kouo" />
            </div>
            <p className="align-center" style={{textAlign: "center"}}>welcome to the Kouo data collection app, if you came here by accident check out more about us at <a href="kouo.io" style={{color: "#777"}}>kouo.io</a>.</p>
            <div className="row margin-center justify-center">
            <div className="login-column">
                    <input
                        id="email-login"
                        value={email}
                        placeholder="email"
                        onChange={(e) => setEmail(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="password-login"
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}>
                    </input>&nbsp;
                    <button
                        onClick={ async () => {
                            if (!email || !password) return alert('Please complete login fields before submitting.')
                            let loggedInUser = await User.login({
                                identifier: email,
                                password: password
                            })
                            if (!loggedInUser.isAxiosError) return setUser(loggedInUser)
                            return alert("Error logging in.")
                        }}
                    >
                        Sign in
                    </button>
                </div>
                <div className="login-column">
                    <label htmlFor="gender">Gender</label>
                    <select
                        id="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="unicorn">Unicorn</option>
                        <option value="other">Other</option>
                    </select>&nbsp;
                    <label htmlFor="age">Age</label>
                    <input
                        id="age"
                        value={age}
                        type="number"
                        onChange={(e) => setAge(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="language"
                        value={language}
                        placeholder="Native Language"
                        onChange={(e) => setLanguage(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="email-signup"
                        value={emailSignup}
                        placeholder="email"
                        onChange={(e) => setEmailSignup(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="password-signup"
                        value={passwordSignup}
                        placeholder="Password"
                        type="password"
                        onChange={(e) => setPasswordSignup(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="device-id"
                        value={deviceId}
                        placeholder="Device ID"
                        onChange={(e) => setDeviceId(e.target.value)}>
                    </input>&nbsp;
                    <input
                        id="signup-code"
                        value={signupCode}
                        placeholder="Signup code"
                        onChange={(e) => setSignupCode(e.target.value)}>
                    </input>&nbsp;
                    <button
                        onClick={ async () => {
                            if (!emailSignup || !gender || !age || !language || !passwordSignup) return alert('Please complete sign-up fields before submitting.')
                            if (signupCode !== "3.14159 on the brain") return alert('Please enter the correct sign up code.')
                            let newUser = await User.register({
                                username: emailSignup,
                                email: emailSignup,
                                gender: gender,
                                age: age,
                                language,
                                password: passwordSignup,
                                deviceId,
                            })
                            if (!newUser.isAxiosError) return setUser(newUser)
                            return alert("Error registering.")
                        }}
                    >
                        Sign up
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login
