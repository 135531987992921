import React, {useState, useEffect} from 'react'

const PegGame = ({ gameEvent }) => {
    const angles = [0, 90, 180, 270]
    const [peg1, setPeg1] = useState(angles[Math.round(Math.random()*4)])
    const [peg2, setPeg2] = useState(angles[Math.round(Math.random()*4)])
    const [peg3, setPeg3] = useState(angles[Math.round(Math.random()*4)])
    const [peg4, setPeg4] = useState(angles[Math.round(Math.random()*4)])
    const [peg5, setPeg5] = useState(angles[Math.round(Math.random()*4)])
    const [peg6, setPeg6] = useState(angles[Math.round(Math.random()*4)])
    const [peg7, setPeg7] = useState(angles[Math.round(Math.random()*4)])
    const [peg8, setPeg8] = useState(angles[Math.round(Math.random()*4)])
    const [firstRow, setFirstRow] = useState(false)
    const [secondRow, setSecondRow] = useState(false)
    const [success, setSuccess] = useState(false)
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        allowSubmit()
    }, [])

    useEffect(() => {
        if (!success) return
        if (peg1 === 90 && (peg2 === 90 || peg2 === 270) && (peg3 === 90 || peg3 === 270) && peg4 === 270) {
            setPeg1(180)
            setPeg2(0)
            setPeg3(0)
            setPeg4(0)
        }
        if (peg5 === 90 && (peg6 === 90 || peg6 === 270) && (peg7 === 90 || peg7 === 270) && peg8 === 270) {
            setPeg5(180)
            setPeg6(0)
            setPeg7(0)
            setPeg8(0)
        }
        setSuccess(false)
    }, [success])

    useEffect(() => {
        if (peg1 === 90 && (peg2 === 90 || peg2 === 270) && (peg3 === 90 || peg3 === 270) && peg4 === 270) {
            setFirstRow(true)
        } else {
            setFirstRow(false)
        }
        if (peg5 === 90 && (peg6 === 90 || peg6 === 270) && (peg7 === 90 || peg7 === 270) && peg8 === 270) {
            setSecondRow(true)
        } else {
            setSecondRow(false)
        }
    }, [peg1, peg2, peg3, peg4, peg5, peg6, peg7, peg8])

    useEffect(() => {
        if (firstRow && secondRow) {
            gameEvent('peg game success')
            setSuccess(true)
        }
    }, [firstRow, secondRow])

    const rotateFcn = (peg) => {
        if (angles[angles.indexOf(peg)+1]) return angles[angles.indexOf(peg)+1]
        return angles[0]
    }

    const allowSubmit = async () => {
        await sleep(300000)
        alert("It has been 5 minutes. You may now submit.")
        gameEvent('peg game time complete')
    }

    return (<>
        <div style={{width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <img onClick={() => {
                gameEvent('clicked peg1')
                setPeg1(rotateFcn(peg1))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg1}deg)`}} src={require("../assets/outerPeg.png")} alt="outer-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg2')
                setPeg2(rotateFcn(peg2))
            }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg2}deg)`}} src={require("../assets/innerPeg.png")} alt="inner-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg3')
                setPeg3(rotateFcn(peg3))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg3}deg)`}} src={require("../assets/innerPeg.png")} alt="inner-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg4')
                setPeg4(rotateFcn(peg4))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg4}deg)`}} src={require("../assets/outerPeg.png")} alt="outer-peg" />
        </div>
        <div style={{width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <img onClick={() => { 
                gameEvent('clicked peg5')
                setPeg5(rotateFcn(peg5))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg5}deg)`}} src={require("../assets/outerPeg.png")} alt="outer-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg6')
                setPeg6(rotateFcn(peg6))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg6}deg)`}} src={require("../assets/innerPeg.png")} alt="inner-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg7')
                setPeg7(rotateFcn(peg7))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg7}deg)`}} src={require("../assets/innerPeg.png")} alt="inner-peg" />
            <img onClick={() => { 
                gameEvent('clicked peg8')
                setPeg8(rotateFcn(peg8))
                }} style={{height: "100px", margin: "1rem", transform: `rotate(${peg8}deg)`}} src={require("../assets/outerPeg.png")} alt="outer-peg" />
        </div>
    </>)
}

export default PegGame