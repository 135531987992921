import React from 'react'
import '../styles/App.css'

import { useGlobalState } from '../components/State'

const ListItem = ({ item, onSelect, disabled }) => {
    const [user, setUser] = useGlobalState('user')

    const highlightOn = (event) => {
        event.stopPropagation()
        if (disabled || event.target.tagName !== ("DIV" || "div")) return
        event.target.classList.add("highlight")
    }
    const highlightOff = (event) => {
        event.stopPropagation()
        if (disabled || event.target.tagName !== ("DIV" || "div")) return
        event.target.classList.remove("highlight")
    }

    return(
        <div
            className="list-item box"
            onClick={() => {
                if (disabled) return
                onSelect(item)
            }}
            onMouseEnter={highlightOn}
            onMouseLeave={highlightOff}
            style={{ backgroundColor: disabled ? "whitesmoke" : "white" }}
        >
            <p style={{fontSize: "1.2rem"}}>{item.Title}</p>
            {disabled ? <img className="complete-tick" src={require("../assets/tick.svg")} alt="completed" /> : null}
        </div>
    )
}

export default ListItem
