import React, {useState, useEffect} from 'react'

const OneBackGame = ({ gameEvent }) => {
    const [values, setValues] = useState([null, null, null])
    const [valueInterval, setValueInterval] = useState()
    const [cross, setCross] = useState(false)
    const [text, setText] = useState(null)
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        allowSubmit()
        
        let internalValues = [0, 0, 0]
        setValueInterval(setInterval(() => {
            setCross(true)
            setTimeout(() => {
                internalValues = [Math.round(Math.random()*9), internalValues[0], internalValues[1]]
                setValues(internalValues)
                setCross(false)
            }, 1000)
        }, 4000))
        return () => clearInterval(valueInterval)
    }, [])

    const allowSubmit = async () => {
        await sleep(300000)
        alert("It has been 5 minutes. You may now submit.")
        gameEvent('1 back time complete')
    }

    const checkMatch = () => {
        if (values[0] === values[2]) {
            (async function() {
                gameEvent('1 back game successful match')
                setText("Correct")
                await sleep(1000)
                setText(null)
            })()
            return
        } 
        (async function() {
            gameEvent('1 back game unsuccessful match')
            setText("Incorrect")
            await sleep(1000)
            setText(null)
        })()
        return
    }

    return (<>
        <div style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(200,200,200,0.9)'}}>
            {text && <h2>{text}</h2>}
            <h1 style={{fontSize: '60px'}}>{cross ? 'x' : values[0]}</h1>
            <button className="big-button" onClick={checkMatch}>1 Back Match</button>
        </div>
    </>)
}

export default OneBackGame