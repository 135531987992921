import React, { useEffect, useState } from "react";
import {
  onHeadsetConnect,
  onHeadsetDisconnect,
  startStream,
  stopStream,
} from "../api/deviceHandle";
import { Mutex } from "async-mutex";

import "../styles/App.css";

import SessionView from "../views/Session";
import Loading from "./Loading";
import Dataset from "../api/Dataset";
import User from "../api/User";

import { useGlobalState } from "./State";

let INFER_DATA;
let ENVIRONMENT;
let BEGIN_SESSION_RECORDING;
let END_SESSION_RECORDING;
let STOP_DATA_RECORDING;

class HeadsetData {
  constructor() {
    this._dataobject = {};
    this._writeLock = new Mutex();
  }

  async getAllData() {
    let dataToReturn;
    let release = await this._writeLock.acquire();
    try {
      dataToReturn = this._dataobject;
      this._dataobject = {};
    } finally {
      release();
    }
    return dataToReturn;
  }

  async insert(timestamp, data) {
    let release = await this._writeLock.acquire();
    try {
      this._dataobject[timestamp] = data;
    } finally {
      release();
    }
  }
}

let HEADSET_DATA = new HeadsetData();

const DataMachine = ({ media, item }) => {
  // state hooks
  const [user, setUser] = useGlobalState("user");
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [cancelSession, setCancelSession] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [chunkCount, setChunkCount] = useState(0);
  const [streamingStarted, setStreamingStarted] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [repeatSession, setRepeatSession] = useState(0);
  const [cam, setCam] = useState(null);
  const [camTimer, setCamTimer] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [inferredData, setInferredData] = useState();

  const dataSendDelay = 10000; // milliseconds between calls to send data

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "dev") {
      ENVIRONMENT = "dev";
      console.log("We are in dev ENVIRONMENT");
    } else {
      ENVIRONMENT = "prod";
    }
  }, []);

  useEffect(() => {
    (async function () {
      if (!user.HasCam) return;
      let mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setCam(mediaStream.getVideoTracks()[0]);
    })();
  }, [user.HasCam]);

  useEffect(() => {
    setConnecting(false);
  }, [connected]);

  useEffect(() => {
    async function postData(data) {
      await Dataset.postRecordingChunk(data.events, data.headset, user.jwt);
    }

    if (streamingStarted) {
      HEADSET_DATA.getAllData()
        .then((data) => {
          if (Object.keys(data).length === 0) {
            alert(
              "No data was received from device. Session will cancel and restart. Please restart the headset"
            );
            createEvent("cancelled session");
          }
          const dataToSend = {
            events: eventData,
            headset: data,
          };
          setEventData([]);
          return dataToSend;
        })
        .catch((err) => {
          console.error("Something went wrong getting the data", err);
        })
        .then((dataToSend) => {
          return postData(dataToSend);
        })
        .catch((err) => {
          console.error("Something went wrong posting data", err);
        })
        .then(() => {
          return sleep(dataSendDelay);
        })
        .catch((err) => {
          console.error("Something went wrong sleeping", err);
        })
        .then(() => {
          setChunkCount(chunkCount + 1);
        });
    }
  }, [chunkCount, streamingStarted]);

  /**
   * Returns Datasets JSON with events and chunks from the last `n` milliseconds
   *
   */

  INFER_DATA = async () => {
    const headsetData = await HEADSET_DATA.getAllData();
    const dataToInfer = {
      events: eventData,
      headset: headsetData,
    };
    let response = await Dataset.inferChunk(dataToInfer, user.jwt);
    setInferredData(response);
  };
  /**
   * Take a photo of the user every 15 seconds
   * @returns {Promise<void>}
   */
  const takePhoto = async () => {
    if (!user.HasCam) return;
    let photoBlob = await new ImageCapture(cam).takePhoto();
    let fileName = `user-${user.id}-session-${
      item.id
    }-${Date.now().toString()}.jpg`;
    let upload = await Dataset.upload(fileName, photoBlob, user.jwt);
    createEvent(
      "photo",
      upload.body && upload.status === 200
        ? upload.body.Location
        : "Picture upload failed."
    );
  };
  /**
   *
   * @returns {Promise<void>}
   */
  const connectFcn = async () => {
    setConnecting(true);
    if (await onHeadsetConnect(createEvent, HEADSET_DATA)) {
      return setConnected(true);
    } else {
      setConnecting(false);
    }
  };

  const createEvent = async (event, data) => {
    switch (event) {
      default:
        const eventObj = { event, time: Date.now(), data: data };
        setEventData((eventData) => [...eventData, eventObj]);
        break;
    }

    if (event === "started session") {
      takePhoto();
      setCamTimer(setInterval(takePhoto, 10000));
    }

    if (event === "cancelled session") {
      setStreamingStarted(false);
      await STOP_DATA_RECORDING();
      setCancelSession(true);

      window.location.reload();
    }

    if (event === "peg game time complete" || event === "1 back time complete")
      setCompleted(true);
  };

  BEGIN_SESSION_RECORDING = async (media) => {
    //Trigger the headset to start streaming data
    await startStream();
    await Dataset.startRecording(media, user);
    await sleep(dataSendDelay);
    setStreamingStarted(true);
  };

  STOP_DATA_RECORDING = async () => {
    setStreamingStarted(false);
    await stopStream();
    let data = await HEADSET_DATA.getAllData();
    console.log("Final data", data);
    // If we have any data left over from the stream, post them
    if (Object.keys(data.headset).length !== 0) {
      await Dataset.postRecordingChunk(data.events, data.headset, user.jwt);
    }
    onHeadsetDisconnect();
  };

  END_SESSION_RECORDING = async (emotions) => {
    await Dataset.endRecording(emotions, user.jwt);
    // stop pictures
    clearInterval(camTimer);
    if (user.HasCam && cam) cam.stop();
    setCam(null);
    let userUpdated = false;
    while (!userUpdated) {
      let userResponse = await User.updateCompletedSessions(user, item);
      if (!userResponse.isAxiosError) userUpdated = userResponse;
    }
    setUser(userUpdated);
  };

  return (
    <>
      {user.HasCam && !cam && (
        <div
          className="fullscreen"
          style={{ backgroundColor: "#707070", opacity: "0.5" }}
        >
          <div style={{ margin: "auto" }}>
            <strong>Accessing camera...</strong>
          </div>
        </div>
      )}
      {connecting && (
        <div
          className="fullscreen"
          style={{ backgroundColor: "rgba(100, 100, 100, 0.5)" }}
        >
          <Loading title="Connecting..." loading={connecting}>
            <p>
              IMPORTANT: If connection continues to load for more than 10
              seconds after device selection: Please unplug devices then plug
              back in and refresh the page.
            </p>
          </Loading>
        </div>
      )}
      <SessionView
        item={item}
        media={media}
        connected={connected}
        createEvent={createEvent}
        setRepeatSession={setRepeatSession}
        inferredData={inferredData}
        setInferredData={setInferredData}
        cancelSession={cancelSession}
      />

      <div className="data-buttons justify-center">
        <button
          onClick={connected || completed ? null : connectFcn}
          className={connected || completed ? "disabled" : null}
          style={{ height: "50px" }}
        >
          {connected ? "Connected" : "Connect"}
        </button>
        <button
          onClick={() => createEvent("cancelled session")}
          style={{ height: "50px" }}
        >
          Cancel Session
        </button>
        <div style={{ height: "30p" }}>&nbsp;</div>
      </div>
    </>
  );
};

export {
  INFER_DATA,
  ENVIRONMENT,
  BEGIN_SESSION_RECORDING,
  END_SESSION_RECORDING,
  STOP_DATA_RECORDING,
};

export default DataMachine;
