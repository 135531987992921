const Auth = {
  getBasicAuth: () => {
    let buff = new Buffer(
      `kouo-strapi:${process.env.REACT_APP_AMYGDALA_SECRET_KEY}`
    );
    return buff.toString("base64");
  },
};

export default Auth;
