import React, {useState, useEffect} from 'react'
import platform from 'platform-detect'
import './styles/App.css'
import './styles/index.css'

import Login from './views/Login'
import Main from './views/Main'
import Policy from './views/Policy'

import { useGlobalState } from './components/State'
import User from './api/User'

function App() {
    const [user, setUser] = useGlobalState('user')
    const [chromeAlert, setChromeAlert] = useState()
    const [serialFlag, setSerialFlag] = useState()

    useEffect(() => {
        if (!platform.chrome) return setChromeAlert(true)

        if (!navigator.serial) return setSerialFlag(true)

        if (!user) return
        (async function() {
            let meCheck = await User.me(user.jwt)
            setUser(meCheck)
        })()
    }, [])

    return (
        <div className="App">
            {chromeAlert && <div className="fullscreen">
                <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(200, 200, 200, 0.9)'
                }}>
                    <div className="container">
                        <h1>You must use Google Chrome browser to use this app.</h1>
                        <p>Sorry. But due to the limited hardware APIs of other browsers we must ask that you use Google Chrome browser with this app.</p>
                    </div>
                </div>
            </div>}
            {serialFlag && <div className="fullscreen">
                <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(200, 200, 200, 0.9)'
                }}>
                    <div className="container">
                        <h1>Great, you are using Google Chrome! Now we just need you to enable experimental features.</h1>
                        <p>In a new tab please navigate to  -{'>'} chrome://flags</p> 
                        <p>Find "Experimental Web Platform Features" and set it to "Enabled" then relaunch Chrome, come back here and voilà!</p>
                    </div>
                </div>
            </div>}
            { (!user || !user.jwt) && <Login /> }
            { user && user.jwt ? user.policyAgreement ? <Main /> : <Policy /> : null }
        </div>
    )
}

export default App
