import React, { useState, useEffect } from "react";
import "../styles/App.css";

import User from "../api/User";
import Session from "../api/Session";
import { useGlobalState } from "../components/State";

import DataMachine from "../components/DataMachine";
import Timer from "../components/Timer";
import ListItem from "../components/ListItem";
import Loading from "../components/Loading";

const Main = () => {
  const [user, setUser] = useGlobalState("user");
  const [media, setMedia] = useState();
  const [selected, setSelected] = useState();
  const [sessions, setSessions] = useState();
  const [timerComplete, setTimerComplete] = useState(true);
  const [allSessionsComplete, setAllSessionsComplete] = useState();

  useEffect(() => {
    (async function () {
      setSessions(await Session.getAll(user.jwt));
    })();
  }, []);

  useEffect(() => {
    if (
      user.hasTimer &&
      user.completedSessions &&
      user.completedSessions[user.completedSessions.length - 1].timeCompleted +
        1000 * 60 * 60 >
        Date.now()
    )
      setTimerComplete(false);
  }, [user]);

  useEffect(() => {
    if (selected) return;
    if (!sessions || !user.completedSessions || typeof sessions != "Array")
      return;
    const matchBoolArray = sessions.map((session) =>
      user.completedSessions.some(
        (compSession) => compSession.id === session.id
      )
    );
    if (
      matchBoolArray.every((item) => item) &&
      user.completedSessions.some(
        (session) =>
          session.id === "peg-game" &&
          user.completedSessions.some((session) => session.id === "1-back-game")
      )
    ) {
      setAllSessionsComplete(true);
      alert(
        "All sessions complete! Thank you! Feel free to redo any sessions and provide more data if you are feeling generous."
      );
    } else setAllSessionsComplete(false);
  }, [sessions, selected]);

  return (
    <>
      {!selected ? (
        <>
          <div className="row">
            <div style={{ flex: "1" }}>
              <Timer
                text={"Time till next session available: "}
                time={
                  user.completedSessions && user.completedSessions[0]
                    ? user.completedSessions[user.completedSessions.length - 1]
                        .timeCompleted +
                      1000 * 60 * 60
                    : 0
                }
                setTimerComplete={setTimerComplete}
                disabled={!user.hasTimer}
              />
            </div>
            <div style={{ flex: "1" }}>
              <img
                style={{ height: "100px", margin: "auto" }}
                src={require("../assets/kouo.png")}
                alt="Kouo"
              />
            </div>
            <div style={{ flex: "1" }}>
              <button
                onClick={() => setUser(User.logout())}
                className="box align-center"
                style={{ margin: "1rem", height: "40px" }}
              >
                Logout
              </button>
            </div>
          </div>
          <div className="container align-center">
            <p style={{ textAlign: "center" }}>
              Check out more about Kouo and what we are doing at{" "}
              <a href="kouo.io" style={{ color: "#777" }}>
                kouo.io
              </a>
              .
            </p>
            <p>
              We will be showing you content and asking you to state the
              emotions that the content evokes in you while recording your
              neurological activity. We will never sell this data, it is used to
              train our algorithm, by proceeding you agree to let us use your
              data for this purpose.
            </p>
            <p>
              It is important to note we will be asking for access to your
              camera during your sessions to be able to take pictures of your
              face, periodically, the purpose of this is to use a emotional
              facial recognition system, this will help us to compare our
              technology to other solutions. When we run these through the
              emotional recognition algorithms the images will be deleted.
            </p>
          </div>
          <h1 style={{ margin: "2rem auto" }}>Sessions</h1>
          {!sessions ? (
            <Loading />
          ) : sessions.isAxiosError ? (
            alert(
              "Error requesting sessions. Your login may have exprired. Please login again."
            )
          ) : (
            <div
              className="align-center"
              style={{ width: "80%", minWidth: "300px", maxWidth: "600px" }}
            >
              {sessions.map((session) => {
                if (session.inference) return;
                let disabled = false;
                if (
                  !allSessionsComplete &&
                  user.completedSessions &&
                  user.completedSessions.some(
                    (compSession) => compSession.id === session.id
                  )
                )
                  disabled = true;
                return (
                  <ListItem
                    item={session}
                    onSelect={(item) => {
                      if (!timerComplete)
                        return alert("Please wait an hour between sessions.");
                      setSelected(item);
                      setMedia("video");
                    }}
                    key={session.id}
                    disabled={disabled}
                  />
                );
              })}
              <h1 style={{ margin: " 2rem auto" }}>Inference Sessions</h1>
              {sessions.map((session) => {
                if (!session.inference) return;
                let disabled = false;
                if (
                  !allSessionsComplete &&
                  user.completedSessions &&
                  user.completedSessions.some(
                    (compSession) => compSession.id === session.id
                  )
                )
                  disabled = true;
                return (
                  <ListItem
                    item={session}
                    onSelect={(item) => {
                      if (!timerComplete)
                        return alert("Please wait an hour between sessions.");
                      setSelected(item);
                      setMedia("video");
                    }}
                    key={session.id}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          )}
          {/* IMPORTANT - GAMES REMOVED UNTIL WE ADD SUPPORT FOR THE IN V2*/}
          {/*<div className="align-center" style={{ width: "80%", minWidth: "300px", maxWidth: "600px", margin: "2.5rem 0" }}>*/}
          {/*    <h1 style={{margin: 'auto'}}>Games</h1>*/}
          {/*    /!* All games ids must end in game *!/*/}
          {/*    <ListItem */}
          {/*        item={{id: 'game-peg-game', Title: 'Peg Game'}} */}
          {/*        onSelect={(item) => {*/}
          {/*            if (!timerComplete) return alert('Please wait an hour between sessions.')*/}
          {/*            setSelected({id: 'peg-game', Title: 'Peg Game'})*/}
          {/*            setMedia('peg-game')*/}
          {/*        }}           */}
          {/*        key={'peg-game'} */}
          {/*        disabled={!allSessionsComplete && user.completedSessions && user.completedSessions.some(session => session.id === "peg-game")}*/}
          {/*    />*/}
          {/*    <ListItem */}
          {/*        item={{id: '1-back-game', Title: '1 Back Game'}} */}
          {/*        onSelect={() => {*/}
          {/*            if (!timerComplete) return alert('Please wait an hour between sessions.')*/}
          {/*            setSelected({id: '1-back-game', Title: 'One Back Game'})*/}
          {/*            setMedia('1-back-game')*/}
          {/*        }}           */}
          {/*        key={'1-back-game'} */}
          {/*        disabled={!allSessionsComplete && user.completedSessions && user.completedSessions.some(session => session.id === "1-back-game")}*/}
          {/*    />*/}
          {/*</div>*/}
        </>
      ) : (
        <>
          <DataMachine media={media} item={selected} />
        </>
      )}
    </>
  );
};

export default Main;
