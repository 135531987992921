import React, { useState, useEffect } from 'react'
import '../styles/App.css'

const Timer = ({ time = 0, text, setTimerComplete, disabled=false, alignStart=true }) => {
    const [mins, setMins] = useState("")
    const [secs, setSecs] = useState("")
    let timerInterval

    useEffect(() => {
        if (disabled || time < 1) {
            setMins("00")
            setSecs("00")
        } else {
            timerFunction()
            if (timerInterval) clearInterval(timerInterval)
            timerInterval = setInterval(timerFunction, 1000)
        }
    }, [disabled])

    useEffect(() => {
        if (!disabled && mins && secs && Number(mins) <= 0 && Number(secs) <= 0) {
            clearInterval(timerInterval)
            setTimerComplete(true)
        }
    }, [disabled, mins, secs])

    const timerFunction = () => {
        // Find the distance between now and provided time
        let distance = time - Date.now()

        if (distance < 0) {
            setMins("00")
            setSecs("00")
            return
        }

        // Time calculations for minutes and seconds
        let distanceMins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString()
        let distanceSecs = Math.floor((distance % (1000 * 60)) / 1000).toString()

        // Display the result via state variables in JSX + add 0 if single digit
        setMins(distanceMins.length < 2 ? "0" + distanceMins : distanceMins)
        setSecs(distanceSecs.length < 2 ? "0" + distanceSecs : distanceSecs)
    }

    return(
        <div className={`timer ${alignStart ? 'align-start' : null}`}>
            <p>{text}</p>
            <div className="box">
                <h2 className="timer-count">{`${mins ? mins : "00"}:${secs ? secs : "00"}`}</h2>
            </div>
        </div>
    )
}

export default Timer
