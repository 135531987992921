import conf from "./apiconfig.json";

let PORT;
let WRITER;
let KEEP_READING = true;
let CLOSED;
let READER;
const ENCODER = new TextEncoder();
let STREAM_STARTED = false;
let DEVICE = conf.deviceConfig[getDeviceVersion()];

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export var onHeadsetConnect = async function (sendEvent, headsetData) {
  const requestOptions = {
    filters: [{ usbVendorId: 0x0403 }],
  };
  PORT = await navigator.serial.requestPort(requestOptions);

  await PORT.open({
    baudRate: DEVICE.baudrate,
  });
  await sendEvent("device version", DEVICE.version);

  READER = PORT.readable.getReader();
  WRITER = PORT.writable.getWriter();

  const connected = await handleHeadphoneConnect();
  if (connected) {
    console.debug("Connected");
    CLOSED = readUntilClosed(headsetData);
    return true;
  } else {
    return false;
  }
};

async function handleHeadphoneConnect() {
  let utf8decoder = new TextDecoder();
  const awaitResponseTime = 500; // ms
  while (true) {
    let softReset = ENCODER.encode("v");
    await WRITER.write(softReset);
    await sleep(awaitResponseTime);

    const { value, closed } = await READER.read();
    if (closed) {
      throw new Error("Connection was closed unexpectedly");
    }
    let response = utf8decoder.decode(value);
    console.log(response);
    let n = response.search("OpenBCI V3 8-16 channel");
    if (n !== -1) return true;
  }
}

async function readUntilClosed(sendDataEvent) {
  let counter = 0;
  const delayFactor = 4; // Delay to compensate for too fast packets from the serial device
  let data = [];

  while (PORT.readable && KEEP_READING) {
    try {
      while (true) {
        const { value, done } = await READER.read();
        if (done) {
          break;
        }
        if (STREAM_STARTED) {
          data = data.concat(Array.from(value));
          if (counter > delayFactor) {
            await sendDataEvent.insert(Date.now(), data);
            counter = 0;
            data = [];
          } else {
            counter = counter + 1;
          }
        }
      }
    } catch (error) {
      console.error("Something went wrong!", error);
    } finally {
      READER.releaseLock();
      WRITER.releaseLock();
    }
  }
  console.debug("Closing port");
  await PORT.close();
}

export async function startStream() {
  const commandArray = new ArrayBuffer(1);
  const commandView = new Uint8Array(commandArray);
  commandView[0] = 0x62;
  // write 'b' to start streaming data
  await WRITER.write(commandArray);
  STREAM_STARTED = true;
}
export async function stopStream() {
  const commandArray = new ArrayBuffer(1);
  const commandView = new Uint8Array(commandArray);
  commandView[0] = 0x73;
  // write 's' to start streaming data
  STREAM_STARTED = false;
  await WRITER.write(commandArray);
}

export async function onHeadsetDisconnect() {
  KEEP_READING = false;
  READER.cancel();
  await CLOSED;
  return true;
}

function getDeviceVersion() {
  return "v2";
}
