import React, {useState} from 'react'
import { useGlobalState } from '../components/State'

import User from '../api/User'

import '../styles/App.css'

const Policy = () => {
    const [user, setUser] = useGlobalState('user')
    const [alert, setAlert] = useState(true)

    return(<>
        {alert && <div className="fullscreen">
            <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(200, 200, 200, 0.9)'
            }}>
                <div className="container">
                    <p>
                        Hi there, thanks so much for coming to the Kouo data collection web-app! We really appreciate your help!
                    </p>
                    <p>
                        We will be showing you content and asking you to state the emotions that the content evokes in you while recording your neurological activity.
                        We will never sell this data, it is used to train our algorithm, by proceeding you agree to let us use your for this purpose. 
                    </p>
                    <p>
                        As a disclaimer, it is important to note we will be asking for <strong>access to your camera</strong> during your sessions to be able to take pictures of your face, periodically, the purpose of this is to use an emotional facial recognition system, this will help us to compare to other solutions. When we run these through the emotional recognition algorithms the images will be deleted.
                    </p>
                </div>
                <button style={{margin: "0.5rem 0", fontSize: "20px"}} onClick={() => setAlert(false)}>I Understand</button>
            </div>
        </div>}
        <div className="container justify-center align-center" style={{height: "80vh"}}>
            <h1>
                Disclaimer
            </h1>
            <p>
                Abandon all ye data those who cross this threshold- you consent to us storing your sign up info on your personal information and then collecting your neural activity.
            </p>
            <h1>
                Privacy
            </h1>
            <p>
                We take privacy very seriously, all data is anonymised and we won’t sell or give on your data to other parties.
            </p>
            <button
                onClick={ async () => {
                    let newUser = await User.update(user.id, {
                        policyAgreement: true
                    }, user.jwt)
                    if (!newUser.isAxiosError) return setUser(newUser)
                    return alert("Error updating Policy Agreement status")
                }}
            >
                Agree
            </button>
        </div>
    </>)
}

export default Policy
