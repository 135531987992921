import React, {useState, useEffect} from 'react'

const MoodMatrix = ({ onSubmit }) => {
    const [happy, setHappy] = useState(3)
    const [excited, setExcited] = useState(3)
    const [control, setControl] = useState(3)
    const [perceivedEmotion, setPerceivedEmotion] = useState("")
    const [hungry, setHungry] = useState(false)
    const [anxious, setAnxious] = useState(false)
    const [inPain, setInPain] = useState(false)
    const [stressed, setStressed] = useState(false)
    const [tired, setTired] = useState(false)
    const [hyperActive, setHyperActive] = useState(false)
    const [nervous, setNervous] = useState(false)

    return(
        <div className="fullscreen">
            <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(150, 150, 150, 0.9)',
                maxHeight: "100vh"
            }}>
                <img
                    src={require('../assets/mood_matrix.png')}
                    alt="Loading..."
                    style={{
                        height: "40vh",
                        maxHeight: "400px"
                    }}
                />
                <strong>Please rate your mood according to the chart above</strong>
                <div style={{flexDirection: "row", flexWrap: "wrap"}}>
                    <div style={{ margin: "0.5rem 1rem 1rem", width: "100px" }}>
                        <label style={{margin: "0.5rem 0", fontSize: "20px"}} htmlFor="happy">Happiness:</label>
                        <select name="happy" id="happy" value={happy} onChange={(e) => setHappy(e.target.value)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                    <div style={{ margin: "0.5rem 1rem 1rem", width: "100px" }}>
                        <label style={{margin: "0.5rem 0", fontSize: "20px"}} htmlFor="excited">Excitement:</label>
                        <select name="excited" id="excited" value={excited} onChange={(e) => setExcited(e.target.value)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                    <div style={{ margin: "0.5rem 1rem 1rem", width: "100px" }}>
                        <label style={{margin: "0.5rem 0", fontSize: "20px"}} htmlFor="control">Control:</label>
                        <select name="control" id="control" value={control} onChange={(e) => setControl(e.target.value)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
                <div style={{ alignItems: "center" }}>
                    <div style={{ margin: "0.5rem 1rem 1rem" }}>
                        <label style={{margin: "0.5rem 0", fontSize: "20px"}} htmlFor="perceived-emotion">What is you current perceived emotion?</label>
                        <select name="perceived-emotion" id="perceived-emotion" onChange={(e) => setPerceivedEmotion(e.target.value)}>
                            <option>Please select an emotion...</option>
                            <option value="relaxed">Relaxed</option>
                            <option value="pensive">Pensive/Thinking</option>
                            {/* <option value="neutral">Neutral</option> */}
                            {/* <option value="confused">Confused</option> */}
                            <option value="happy">Happy</option>
                            <option value="angry">Angry/Irritated/Frustrated</option>
                            <option value="scared">Scared</option>
                            {/* <option value="bored">Bored</option> */}
                            <option value="sad">Sad</option>
                            <option value="stressed">Stressed</option>
                            <option value="focused">Focused</option>
                            {/* <option value="amused">Amused</option> */}
                            {/* <option value="shocked">Shocked</option> */}
                            {/* <option value="disgust">Disgusted</option> */}
                            {/* <option value="dislike">Dislike/Contempt</option> */}
                        </select>
                    </div>
                    <div style={{ margin: "0.5rem 1rem 1rem" }}>
                        <p style={{margin: "0.5rem 0", fontSize: "20px"}}>Select any that apply to your current state (leave blank if none):</p>
                        <div className="row align-center">
                            <div className="row align-center">
                                <input 
                                    name="hungry-checkbox" 
                                    type="checkbox" 
                                    value={hungry} 
                                    onChange={() => setHungry(!hungry)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="hungry-checkbox"><p> Hungry</p></label>
                            </div>
                            <div className="row align-center">
                                <input 
                                    name="anxious-checkbox" 
                                    type="checkbox" 
                                    value={anxious} 
                                    onChange={() => setAnxious(!anxious)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="anxious-checkbox"><p> Anxious</p></label>
                            </div>
                            <div className="row align-center">
                                <input 
                                    name="in-pain-checkbox" 
                                    type="checkbox" 
                                    value={inPain} 
                                    onChange={() => setInPain(!inPain)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="in-pain-checkbox"><p> In Pain</p></label>
                            </div>
                        </div>
                        <div className="row align-center">
                            <div className="row align-center">
                                <input 
                                    name="stressed-checkbox" 
                                    type="checkbox" 
                                    value={stressed} 
                                    onChange={() => setStressed(!stressed)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="stressed-checkbox"><p> Stressed</p></label>
                            </div>
                            <div className="row align-center">
                                <input 
                                    name="tired-checkbox" 
                                    type="checkbox" 
                                    value={tired} 
                                    onChange={() => setTired(!tired)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="tired-checkbox"><p> Tired</p></label>
                            </div>
                            <div className="row align-center">
                                <input 
                                    name="hyper-active-checkbox" 
                                    type="checkbox" 
                                    value={hyperActive} 
                                    onChange={() => setHyperActive(!hyperActive)}
                                    style={{width: "auto", margin: "auto 0.5rem"}}></input>
                                <label htmlFor="hyper-active-checkbox"><p> Hyperactive</p></label>
                            </div>
                        </div>
                        <div className="row align-center">
                            <input 
                                name="nervous-checkbox" 
                                type="checkbox" 
                                value={nervous} 
                                onChange={() => setNervous(!nervous)}
                                style={{width: "auto", margin: "auto 0.5rem"}}></input>
                            <label htmlFor="nervous-checkbox"><p> Nervous</p></label>
                        </div>
                    </div>
                </div>
                <button style={{margin: "0.5rem 0", fontSize: "20px"}} onClick={() => {
                    if (!happy || !excited || !control || !perceivedEmotion) return alert('Please input all mood information before submitting.')
                    let currentState = []
                    if (hungry) currentState.push("hungry")
                    if (anxious) currentState.push("anxious")
                    if (inPain) currentState.push("in pain")
                    if (stressed) currentState.push("stressed")
                    if (tired) currentState.push("tired")
                    if (hyperActive) currentState.push("hyperactive")
                    if (nervous) currentState.push("nervous")
                    onSubmit(happy, excited, control, perceivedEmotion, currentState)
                }}>Submit</button>
            </div>
        </div>
    )
}

export default MoodMatrix