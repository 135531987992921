import axios from "axios";
import conf from "./apiconfig.json";
let env = "dev";
if (process.env.REACT_APP_ENV === "prod") {
  env = "prod";
}
const Session = {
  getAll: async (jwt: string) => {
    return axios
      .get(conf.envs[env]["strapi-url"] + `/sessions`, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        return response.data.sort(function (a: any, b: any) {
          if (
            Number(a.Title.split(" ")[1]) < Number(b.Title.split(" ")[1]) ||
            a.Title.includes("Debug")
          )
            return -1;
          if (Number(a.Title.split(" ")[1]) > Number(b.Title.split(" ")[1]))
            return 1;
          return 0;
        });
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  getMedia: async (id: string, jwt: string) => {
    return axios
      .get(conf.envs[env]["strapi-url"] + `/media/${id}`, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
};

export default Session;
